import Proptypes from 'prop-types';
import React, { useState } from 'react';
import { useIntl, FormattedMessage, FormattedHTMLMessage } from 'react-intl';

// Own component
import SEO from '../../components/seo';
import { CustomLink } from '../../utils/navigation';
import { MediaTag } from '../../components/MediaTag';
import { useLanguages } from '../../hooks/useLanguages';
import SectionCover from '../../components/SectionCover/sectionCover';

const Product = ({ id: productId, name, multimedia, description, extraDescription, coverImage }) => {
  const { langKey } = useLanguages();
  const { formatMessage } = useIntl();
  const [media, setMedia] = useState(multimedia[0])
  const translatedName = name.split(' ').length > 1 ? name : formatMessage({ id: name })

  const handleImageClick = (ev, mediaId) => {
    ev.preventDefault();
    ev.stopPropagation();

    const element = multimedia.find(el => el.id === mediaId);
    setMedia(element);
  }

  return (
    <>
      <SEO title={translatedName} />
      <SectionCover title={translatedName} src={coverImage}/>
      <section className="pad100-top-bottom">
        <div className="container">
          <div className="wdt-100 marbtm50">
            <div className="col-md-8">
              <MediaTag id={media.id} url={media.url} type={media.type} height={600} className="img-responsive" />
              <div className="grid-images">
                {multimedia.map(({ id: mediaId, url, type }) => (
                  <button key={`button-${mediaId}`} className="module-button" onClick={(ev) => handleImageClick(ev, mediaId)}>
                    <MediaTag key={mediaId} id={mediaId} url={url} type={type} height={175} className="img-responsive module-image" />
                  </button>
                ))}
              </div>
            </div>
            <div className="col-md-4">
              <div className="product-desc">
                <h2>{translatedName}</h2>
                <p className="fnt-17 text-justify">
                  <FormattedHTMLMessage id={description} />
                </p>
                <CustomLink
                  state={{ product: productId, type: 'product' }}
                  to={langKey === 'en' ? '/en/contact' : '/contact'}
                  className="header-requestbtn filter-link add-cart-link hvr-bounce-to-right"
                >
                  <FormattedMessage id="contactUs" values={{ prefix: '' }} />
                </CustomLink>
              </div>
            </div>
          </div>
          <div className="wdt-100 marbtm50">
            {extraDescription && (
              <p className="fnt-17 text-justify">
                <FormattedHTMLMessage id={extraDescription} />
              </p>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

Product.propTypes = {
  id: Proptypes.string.isRequired,
  name: Proptypes.string.isRequired,
  multimedia: Proptypes.arrayOf(Proptypes.shape({
    id: Proptypes.string,
    type: Proptypes.string,
    url: Proptypes.string,
  })).isRequired,
  description: Proptypes.string.isRequired,
  coverImage: Proptypes.string,
  extraDescription: Proptypes.string,
}

export default Product;
